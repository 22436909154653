.home-body {
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://live.staticflickr.com/65535/51936635133_c1c1b359c8_b.jpg");
  background-size: cover;
  background-position: center;
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}
.cover-container {
  max-width: 80vw;
}

.nav-link {
  padding: 0.25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 1rem;
  border-bottom: 0.25rem solid transparent;
}

.nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
  /* border-bottom-color: rgba(255, 255, 255, 0.5); */
}

.nav-link.active {
  color: white;
  /* border-bottom-color: white; */
}

.btn-secondary,
.btn-secondary:hover {
  color: #333;
  text-shadow: none;
}

@media (max-height: 500px) {
  .home-body {
    height: fit-content;
  }
}
